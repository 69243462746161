.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none !important;
  box-shadow: none !important;
}
@include media(min-width, 576px) {
  #navbarNav {
    margin-right: 50px;
  }
  .nvContainer {
    padding-top: 0 !important;
  }
}
@include media(max-width, 576px) {
  .nvContainer {
    padding-top: 1rem;
  }
  .searchInput {
    width: 100% !important;
  }
}
