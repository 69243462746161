.slideShow-text-heading {
  font-size: 5rem;
  text-shadow: 2px 2px 5px black;
}
.slideShow-text-content {
  font-size: 1.3rem;
  text-shadow: 2px 2px 2px black;
}
@include media(max-width, 900px) {
  .slideShow-text-heading {
    font-size: 3rem;
  }
  .slideShow-text-content {
    font-size: 1rem;
  }
}
@include media(max-width, 670px) {
  .slideShow-text-heading {
    font-size: 2rem;
  }
  .slideShow-text-content {
    font-size: 0.9rem;
  }
}
@include media(max-width, 370px) {
  .slideShow-text-heading {
    font-size: 1.5rem;
  }
  .slideShow-text-content {
    font-size: 0.8rem;
  }
}
section {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  position: relative;
}
