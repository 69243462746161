.footer-inputField {
  border-radius: 0 !important;
  background-color: transparent !important;
  color: white !important;
  &::placeholder {
    color: white !important;
  }
  &::-moz-placeholder {
    color: white !important;
  }
  &::-webkit-input-placeholder {
    color: white !important;
  }
}

#footer-sendMessageBtn {
  border: 1px solid white;
  border-radius: 0;
  color: white;
  &:hover {
    background-color: white;
    color: var(--navBarBG);
  }
}
.footerLinks {
  display: flex;
  align-items: center;
  color: var(--navBarBG);
  font-size: 1.5rem;
  margin-right: 10px;
}
.footerText-Bottom {
  margin-bottom: 0;
}
@include media(max-width, 767px) {
  #contact {
    min-height: 88% !important;
  }
  .footerText-Container {
    min-height: 12% !important;
  }
  .footerText-Bottom {
    text-align: center;
    margin-bottom: 1rem;
  }
  .footerLinks-Container {
    justify-content: center !important;
  }
}
