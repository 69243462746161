// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------
@mixin media($type, $width) {
  @media only screen and ($type: $width) {
    @content;
  }
}
@mixin bd-radius($value) {
  -webkit-border-radius: $value;
  -moz-border-radius: $value;
  border-radius: $value;
}
