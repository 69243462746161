// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

@tailwind base;
@tailwind components;
@tailwind utilities;

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}
/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  // box-sizing: inherit;
  box-sizing: border-box;
}

main {
  transition-property: -moz-filter, -ms-filter, -o-filter, -webkit-filter,
    filter;
  transition-duration: 0.4s;
  width: inherit;
  outline: none;
  z-index: -1;
  // position: absolute;
}
html,
body {
  width: 100%;
  margin: 0;
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}
