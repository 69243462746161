.productEditButton {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: rgb(184, 184, 184);
  }
}
.productDeleteButton {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: rgb(255, 19, 19);
  }
}
