.cart-PriceContainer {
  width: 100px;
  display: flex;
  flex-direction: column;
  margin: 0 2rem;
  align-items: center;
  .cart-PriceContainer-title {
    width: max-content;
    font-size: 1.4rem;
    font-weight: bold;
  }
  .cart-PriceContainer-price {
    width: max-content;
    font-size: 1.2rem;
  }
}
@include media(max-width, 630px) {
  .cart-CardPriceContainer {
    margin-left: 0 !important;
  }
  .cart-CardDeleteBtn {
    margin-right: 0 !important;
  }
  .cart-cardQuantityContainer {
    margin-right: 0 !important;
  }
  .cart-CardPrice-price {
  }
  .cart-CardPrice-heading {
  }
  .cart-CardContainer {
    padding-left: 10px !important;
    padding-right: 10px !important;
    width: 100%;
    border-right: none !important;
    border-left: none !important;
    flex-direction: column;
  }
  .cart-CardTopPane {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .cart-CardBottomPane {
    width: 100%;
    justify-content: space-between;
  }
}
.cart-bottomButtons {
  width: 100px;
  height: 40px;
  // background: var(--navBarBG);
  background-color: transparent;
  border: 2px solid var(--navBarBG);
  color: black;
  &:hover {
    background-color: var(--navBarBG);
    color: white;
  }
}
@include media(max-width, 460px) {
  .cart-PriceContainer {
    margin: 0 0.8rem !important;
  }
  .cart-PriceContainer-title {
    width: max-content;
    font-size: 1rem !important;
    font-weight: bold;
  }
  .cart-PriceContainer-price {
    width: max-content;
    font-size: 0.8rem !important;
  }
}
