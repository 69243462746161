.categoriesCard-Container {
  width: 300px;
  height: 400px;
  background-color: black;
  position: relative;
}
.categoriesCard-Image {
  width: 300px;
  height: 400px;
  object-fit: cover;
  opacity: 0.8;
}
@property --darkPortionGradient {
  syntax: "<color>";
  initial-value: rgba(0, 0, 0, 0);
  inherits: false;
}
@property --lightPortionGradient {
  syntax: "<color>";
  initial-value: rgba(0, 0, 0, 0);
  inherits: false;
}
.categoriesCard-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  background: linear-gradient(
    to top,
    var(--darkPortionGradient),
    var(--lightPortionGradient),
    var(--darkPortionGradient)
  );
  transition: --darkPortionGradient 0.2s, font-size 0.2s, box-shadow 0.2s;
  &:hover {
    --darkPortionGradient: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 2.3rem;
  }
}
#notFound-Icon {
  user-select: none;
  cursor: default;
  animation: notFoundIconAnimate 1.5s linear infinite;
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
}
@keyframes notFoundIconAnimate {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(20deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-20deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
